import { ResultsPage } from "./GenericResultsPage";
import ManagementImage from "../assets/images/management.svg";
import { BUSINESS_MODELS, BUSINESS_MODEL_DATA } from "../Utils/constants";
import { useFormDataContext } from "../Utils/formDataContext";

const questionsList = [
  {
    id: 1,
    content: `Can you adjust the prices to the remaining supply or the increased demand?`,
  },
  {
    id: 2,
    content: `Is there sufficient knowledge about the customer, supply and demand and data to correctly manage this model?`,
  },
  { id: 3, content: `What advantages does the yield management model offer?` },
  {
    id: 4,
    content: `Do customers accept price fluctuations or is this an obstacle for your customers?`,
  },
];

export const ManagementResults = () => {
  const { result, companyName } = useFormDataContext();

  return (
    <ResultsPage
      image={ManagementImage}
      imageTitle={BUSINESS_MODELS.YIELD_MANAGEMENT}
      quotation={`"Yield management means that the price is constantly changing. The price is based on supply and demand. Control takes place on maximizing profit."`}
      IdeaList={result.Ideas}
      subtitle={`Below are our Yield Management ideas for a ${companyName}`}
      CompanyName={companyName}
      pros={BUSINESS_MODEL_DATA[BUSINESS_MODELS.YIELD_MANAGEMENT].opportunities}
      cons={BUSINESS_MODEL_DATA[BUSINESS_MODELS.YIELD_MANAGEMENT].challenges}
      modelType={BUSINESS_MODELS.YIELD_MANAGEMENT}
      questionsList={
        BUSINESS_MODEL_DATA[BUSINESS_MODELS.YIELD_MANAGEMENT].questions
      }
    />
  );
};
