import { Box, Typography } from "@mui/material";

export const IdeaCard = ({ image, title, subtitle, index }) => {
  return (
    <Box
      display={"flex"}
      border={"1px solid #000000"}
      borderRadius={"11px"}
      flexDirection={{lg: 'row',md: 'column', xs: 'column'}}
    >
      <Box
        display={"flex"}
        flex={1 / 4}
        bgcolor={"#D9D9D999"}
        flexDirection={"column"}
        sx={{ justifyContent: "center", alignItems: "center" , }}
        borderRadius={"11px"}
        padding={"20px"}
      >
        <img src={image} width={"80px"} height={"80px"} />
        <Typography fontSize={"14px"} fontWeight={700}>
          Idea {index}
        </Typography>
      </Box>
      <Box
        flex={3 / 4}
        padding={"40px"}
        sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center",
          minHeight: "100px",
          maxHeight: "auto",
         }}
      >
        <Typography fontWeight={700}>
          {title}: <span style={{fontWeight: 400}}>{subtitle}</span>
        </Typography>
      </Box>
    </Box>
  );
};
