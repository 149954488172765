import { Box, Card, CardContent, Typography, useMediaQuery } from "@mui/material";
import FounderImage from "../assets/images/founderImage.svg";

export const GenericCard = ({ title, subtitle, quote, children }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <Card
      sx={{
        // height: "650px",
        // width: "1000px",
        background: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '16px',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(2.1px)',
        '-webkit-backdrop-filter': 'blur(2.1px)',
        paddingX: "10%",
        paddingY: '10px',
        marginBottom: '10px',
      }}
    >
      <CardContent>
        <Typography textAlign={"center"} lineHeight={isMobile && 1.2} fontWeight={isMobile ? 800: 800} fontSize={isMobile ? "30px" : "36px"}>
          {title}
        </Typography>
        <Typography
          textAlign={"center"}
          fontWeight={400}
          fontStyle={"italic"}
          fontSize={"18px"}
          marginTop={isMobile && "20px"}
        >
          {subtitle}
        </Typography>
        <Box>
          <Box paddingTop={"50px"} paddingBottom={"50px"} display={"flex"}>
            {children}
          </Box>
        {!isMobile && <Box
            borderRadius={"11px"}
            border={"1px solid #ccc"}
            boxShadow={"0px 4px 4px 0px #ddd"}
            display={"flex"}
            flex={1}
            flexDirection={{lg : "row", xs: 'column'}}
            padding={"15px"}
          >
            <Box flex={1 / 5} paddingRight='10px' display={'flex'} justifyContent={{xs: 'center'}}>
              <img
                src={FounderImage}
                width={"132px"}
                height={"132px"}
                className="center"
                style={{ borderRadius: "50%"}}
              />
            </Box>
            <Box flex={4 / 5}>
              <Typography
                fontWeight={400}
                fontSize={"18px"}
                fontStyle={"italic"}
                paddingBottom={"20px"}
              >
                {quote}
              </Typography>
              <Typography
                fontWeight={800}
                fontSize={"18px"}
                fontStyle={"italic"}
              >
                {"Robbert Fransen"}
              </Typography>
              <Typography
                fontWeight={400}
                fontSize={"18px"}
                fontStyle={"italic"}
              >
                Founder Business Model Hacking
              </Typography>
            </Box>
          </Box>}
        </Box>
      </CardContent>
    </Card>
  );
};
