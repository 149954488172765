import { Box, Container, useMediaQuery } from "@mui/material";
import MenuAppBar from "./AppBar";
import BackgroundImage from "../../src/assets/images/background.jpeg";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";

export const GenericPageLayout = ({ children }) => {
  const location = useLocation();

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      <MenuAppBar />
      <Box
        minHeight={isMobile ? "85vh" : "82vh"}
        // height={"100vh"}
        // paddingY={"50px"}
        bgcolor={"transparent"}
        sx={{
          marginTop: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          // backgroundImage: `url(${BackgroundImage})`,
          // backgroundSize: "cover",
        }}
      >
        <Container style={{ margin: "0px" }} maxWidth={"lg"}>
          <AnimatePresence mode="wait">
            <motion.div
              key={location.pathname}
              initial={{ opacity: 0, translateY: 100 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateX: -100 }}
              transition={{ duration: 0.5, type: "tween" }}
              // component={motion.div}
              width={"100%"}
            >
              {children}
            </motion.div>
          </AnimatePresence>
        </Container>
      </Box>
    </>
  );
};
