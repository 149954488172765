import {
  Box,
  Button,
  Icon,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import { motion } from "framer-motion";

const GenericSocialButton = ({ iconName, buttonName, buttonColor }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Button
      variant="contained"
      sx={{
        marginTop: 1,
        textTransform: "none",
        bgcolor: buttonColor,
        width: isMobile ? "100%" : "150px",
        "&:hover": {
          backgroundColor: buttonColor,
        },
      }}
      component={motion.div}
      whileHover={{
        scale: 1.1,
        transition: { duration: 0.3 },
      }}
      whileTap={{ scale: 0.9 }}
    >
      <Stack display={"flex"} flexDirection={"row"} columnGap={1}>
        {iconName}
        {buttonName}
      </Stack>
    </Button>
  );
};
export const SocialMediaContainer = ({
  CompanyName,
  IdeaList,
  image,
  imageTitle,
  quotation,
  subtitle,
  pros,
  cons,
  modelType,
  questionsList,
}) => {
  return (
    <Box
      sx={{
        marginTop: 5,
      }}
    >
      <Typography fontSize={14} paddingTop={"2px"} fontWeight={700}>
        Share this result:{" "}
      </Typography>
      <Stack
        display={"flex"}
        flexDirection={{ lg: "row", md: "row", xs: "column" }}
        columnGap={2}
        marginTop={"5px"}
      >
        {/* <GenericSocialButton
          iconName={<LinkedInIcon />}
          buttonName={"LinkedIn"}
          buttonColor={"#1178b3"}
        /> */}
        {/* <a
          href={`mailto:?subject=Check out this ${modelType} idea for ${CompanyName}&body=${encodeURIComponent(
            `I found this ${modelType} idea for ${CompanyName} and thought you might be interested in it. ${quotation}\n${IdeaList.map(
              (idea, index) => `\n${index + 1}: ${idea.title}\n\t${idea.subtitle}`
            ).join("")} \n\n`
          )}`}
        ></a> */}
        <a
          href={`mailto:?subject=🚀Discover Business Model Hacking | Ready to boost your business? &body=${encodeURIComponent(
            `Hi,

I wanted to share a fantastic innovation method I've recently discovered: Business Model Hacking. It's an innovative approach that helps to create new ideas for businessmodels and it’s very practical and easy to apply.

Here are some links I'd like to share with you:
👉 Website -> www.businessmodelhacking.com
Here you can learn more about Business Model Hacking.

👉 Demo tool -> http://www.ideahacker.nl
Here you can experience how it works with the IdeaHacker, an AI-ideationtool.

👉 Book: Business Model Hacking -> http://www.businessmodelhacking.com/bookgift
Here you can download the full e-book Business Model Hacking for free.

I hope this inspires you!

Regards,`
          )
            .replace(/🚀/g, "%F0%9F%9A%80" )
            .replace(/\n/g, "%0D%0A")
            .replace(/ /g, "%20")
            .replace(/👉/g, "%F0%9F%91%89")}`}
        >
          {/* <a
          href={`mailto:?subject=🚀 Discover Business Model Hacking | Ready to boost your business? 🚀 &body=${encodeURIComponent(`Hi,\n I wanted to share a fantastic innovation method I've recently discovered: Business Model Hacking. It's an innovative approach that helps to create new ideas for businessmodels and it’s very practical and easy to apply. \n
          ${quotation}\n${IdeaList.map(
              (idea, index) => `\n${index + 1}: ${idea.title}\n\t${idea.subtitle}`
            ).join("")} \n\n
          Here are some links I'd like to share with you:
           👉  Website -> www.businessmodelhacking.com
          Here you can learn more about Business Model Hacking.
          👉  Demo tool -> http://www.ideahacker.nl
          Here you can experience how it works with the IdeaHacker, an AI-ideationtool.
          👉  Book: Business Model Hacking -> http://www.businessmodelhacking.com/bookgift
          Here you can download the full e-book Business Model Hacking for free.

          I hope this inspires you!Regards,`
          )}`}
        > */}
          <GenericSocialButton
            iconName={<EmailIcon />}
            buttonName={"Email"}
            buttonColor={"#f06630"}
            // onClick={() => {
            //   window.
            // }}
          />
        </a>
      </Stack>
    </Box>
  );
};
