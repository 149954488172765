import { TextField as MuiTextField } from "@mui/material";

export const TextField = (props) => {
  return (
    <MuiTextField
      variant="standard"
      autoComplete="off"
      sx={{
        // backgroundColor: "none !important",
        marginTop: "20px",
        borderBottomStyle: "dashed",
        borderWidth: "2px",
        width: "100%",
        "& .MuiFilledInput-root": {
          background: "transparent",
        },
      }}
      InputProps={{ disableUnderline: true }}
      {...props}
    />
  );
};
