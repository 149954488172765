import { ResultsPage } from "./GenericResultsPage";
import SettingsImage from "../assets/images/settingsImage.svg";
import { useFormDataContext } from "../Utils/formDataContext";
import { BUSINESS_MODELS, BUSINESS_MODEL_DATA } from "../Utils/constants";

const questionsList = [
  ~{
    id: 1,
    content: `Is extra value created for the customer when introducing the pay per use model?`,
  },
  {
    id: 2,
    content: `What are the consequences of introducing this model for the other building blocks of the business model?`,
  },
  {
    id: 3,
    content: `Will customers change their behavior when introducing the pay per use model?`,
  },
  {
    id: 4,
    content: `Can your company beat the competition by switching to pay per use?`,
  },
];

export const PayPerUseResults = () => {
  const { result, companyName } = useFormDataContext();
  return (
    <ResultsPage
      image={SettingsImage}
      imageTitle={BUSINESS_MODELS.PAY_PER_USE}
      quotation={`"Make your customer pay for the actual consumption of the offer."`}
      IdeaList={result.Ideas}
      subtitle={`Below are our Pay per use ideas for a ${companyName}`}
      CompanyName={companyName}
      pros={BUSINESS_MODEL_DATA[BUSINESS_MODELS.PAY_PER_USE].opportunities}
      cons={BUSINESS_MODEL_DATA[BUSINESS_MODELS.PAY_PER_USE].challenges}
      modelType={BUSINESS_MODELS.PAY_PER_USE}
      questionsList={BUSINESS_MODEL_DATA[BUSINESS_MODELS.PAY_PER_USE].questions}
    />
  );
};
