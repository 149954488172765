import { Box, Typography, useMediaQuery } from "@mui/material";
import GlobeImage from "../assets/images/globe.svg";
import { GenericPageLayout } from "../components/GenericPageLayout";
import { useFormDataContext } from "../Utils/formDataContext";
import { useEffect } from "react";
import { useDataProvider } from "../Utils/dataprovider";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from 'react-lottie';


export const GenerateIdeasPage = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const location = useLocation();

  const { setResult, companyName, companyType, businessModel, setHeader } = useFormDataContext();
  let initial = true;

  const sample_data = {
    Explanation:
      "The 'pay per use' business model involves charging customers based on the amount of product or service they use instead of a flat fee. This could be relevant for a civil engineering construction company like AlphaX Construction as it allows for more flexibility and cost-effectiveness for clients who may only need certain services or materials for their project. It also incentivizes the company to provide efficient and high-quality work to minimize costs for the client.",
    Ideas: [
      {
        title: "Modular Building Rentals",
        subtitle:
          "Rent fully equipped and customizable modular buildings for temporary use. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et, consectetur libero. Nulla nec purus feugiat, molestie ipsum et, consectetur libero.", 
          
      },
      {
        title: "Smart Road Infrastructure",
        subtitle:
          "Charge drivers for using roads equipped with smart technology for efficient traffic flow.",
      },
      {
        title: "Eco-Friendly Construction Materials",
        subtitle:
          "Offer sustainable building materials for rent, reducing waste and saving costs.",
      },
    ],
    Opportunities: [
      "Flexible Pricing Options: By implementing a pay per use model, AlphaX Construction can offer their clients flexible pricing options. This means that clients only pay for the services they use, instead of committing to a fixed price for the entire project. This can attract clients who are on a tight budget or have varying project needs.",
      "Increased Market Reach: By offering a pay per use model, AlphaX Construction can tap into a larger market of clients who may not have the financial resources to commit to a fixed price project. This can help the company expand its customer base and reach new demographics.",
      "Cost Savings for Clients: Clients can benefit from the pay per use model as they only have to pay for the specific services they require. This can save them money compared to traditional fixed price models where they may end up paying for services they do not need.",
      "4. Scalability: The pay per use model can provide AlphaX Construction with the ability to scale their services based on demand. This means that the company can easily adjust its resources and services to accommodate projects of varying sizes and complexities.",
      "Lower Risk for Clients: With the pay per use model, clients do not have to commit to a large upfront payment or a long-term contract. This lowers their risk and allows them to try out the services of AlphaX Construction without a large financial commitment.",
      "Potential for Recurring Revenue: The pay per use model can open up opportunities for recurring revenue for AlphaX Construction. Clients who",
    ],
    Challenges: [
      "Difficulty in predicting revenue: With a pay-per-use model, it can be challenging to accurately predict the revenue of the company as it is dependent on the number of users and their usage patterns.",
      "Cash flow management: The fluctuating revenue stream from a pay-per-use model can make it difficult to manage the company's cash flow, especially during periods of low usage.",
      "Customer acquisition: Attracting and retaining customers can be a challenge as the pay-per-use model may not be appealing to all clients, especially those who prefer a fixed price for services.",
      "Competition: The pay-per-use model is becoming increasingly popular in the construction industry, leading to stiff competition for AlphaX Construction from other companies that offer similar services.",
      "Demand fluctuations: The demand for construction services can be unpredictable, leading to inconsistent usage and revenue patterns for AlphaX Construction.",
      "Infrastructure costs: The pay-per-use model may require significant investments in infrastructure, such as equipment and technology, to track and measure usage accurately.",
      "Technical challenges: Implementing and maintaining the technology required for a pay-per-use model can be complicated and may require specialized skills and resources.",
      "Pricing structure: Setting the right pricing structure for the pay-per-use model can be challenging, as it needs to be competitive while still ensuring profitability for AlphaX Construction.",
      "Contract management: Managing contracts with clients can be complex in a pay-per-use model, as it may involve negotiating usage rates and tracking usage accurately",
    ],
    Questions: [
      "How can we improve our pay-per-use model to make it more attractive to potential clients?",
      "What strategies can we implement to increase our customer retention rate?",
      "Are there any new technologies or techniques we should consider incorporating into our construction processes?",
      "How can we streamline our operations to reduce costs and increase efficiency?",
      "Are there any untapped markets or industries that we could target with our pay-per-use model?",
      "How can we differentiate ourselves from our competitors in the crowded construction industry?",
      "Are there any partnerships or collaborations we could pursue to expand our reach and services?",
      "How can we effectively market our pay-per-use model to potential clients?",
      "Are there any potential risks or challenges associated with our current business model that we should address?",
      "How can we incorporate sustainability and eco-friendly practices into our construction processes?",
    ],
  };

  useEffect(() => {
    if (initial) {
      initial = false;
      // setResult(sample_data);
      // setTimeout(() => navigate("/review-ideas"), 1000); 
      // setTimeout(() => {
      // navigate("/results")}, 4000);

      dataProvider
        .getIdeas({
          business_model_hack: businessModel,
          company_type: companyType,
          company_name: companyName,
        })
        .then((data) => {
          console.log({ data });
          setResult(data.data);
        })
        .then(() => {
          console.log("Navigating to review ideas");
          // setTimeout(() => navigate("/review-ideas"), 1000);
          setTimeout(() => {
            navigate("/results")}, 4000);
        });
    }
  }, []);

  return (
    <GenericPageLayout>
      <Box
        display={"flex"}
        sx={{ justifyContent: "center", alignItems: "center"}}
      >
        <Box
          width={ isMobile ? "auto" : "70%"}
          height={isMobile ? "auto" : "70%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: require('../assets/animations/animation1.json'),
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
            height={350}
            width={350}

          />
          {/* <img
            src={GlobeImage}
            height={"300px"}
            width={"300px"}
            style={{ alignSelf: "center" }}
            alt="globe"
          /> */}

          <Typography
            fontWeight={800}
            fontSize={{ lg: "36px", md: "30px", xs: "26px" }}
            color={"white"}
            textAlign={"center"}
          >
            {/* Our AI is busy to generate new ideas for {companyName} */}
            Congratulations innovator, your first result with our IdeaHacker is ready to review.

          </Typography>
        </Box>
      </Box>
    </GenericPageLayout>
  );
};
