import { ResultsPage } from "./GenericResultsPage";
import PerformanceImage from "../assets/images/performanceImage.svg";
import { BUSINESS_MODELS, BUSINESS_MODEL_DATA } from "../Utils/constants";
import { useFormDataContext } from "../Utils/formDataContext";

const questionsList = [
  {
    id: 1,
    content: `What do your company's customers really need?`,
  },
  {
    id: 2,
    content: `Is it possible to link the result to a pre-agreed amount?`,
  },
  { id: 3, content: `How can your company improve delivery and reliability in the value chain?` },
  {
    id: 4,
    content: ` Can the revenue model be split into a fixed part and a flexible part, whereby customers pay if the agreed performance is achieved?`,
  },
];

export const PerformanceResults = () => {
  const { result, companyName} = useFormDataContext()

  return (
    <ResultsPage
      image={PerformanceImage}
      imageTitle={BUSINESS_MODELS.PERFORMANCE_BASED_CONTRACTING}
      quotation={`"Linking the price to an achievement or the end result. The result is measured and the customer pays an agreed amount for this. Usually all relevant costs are charged in this amount."`}
      IdeaList={result.Ideas}
      subtitle={`Below are our Performance based contracting ideas for a ${companyName}`}
      CompanyName={companyName}
      pros={BUSINESS_MODEL_DATA[BUSINESS_MODELS.PERFORMANCE_BASED_CONTRACTING].opportunities}
      cons={BUSINESS_MODEL_DATA[BUSINESS_MODELS.PERFORMANCE_BASED_CONTRACTING].challenges}
      modelType={BUSINESS_MODELS.PERFORMANCE_BASED_CONTRACTING}
      questionsList={BUSINESS_MODEL_DATA[BUSINESS_MODELS.PERFORMANCE_BASED_CONTRACTING].questions}
    />
  );
};
