import { Box, Button } from "@mui/material";
import { GenericCard } from "../components/GenericCard";
import { GenericPageLayout } from "../components/GenericPageLayout";
import { useState } from "react";
import { useFormDataContext } from "../Utils/formDataContext";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { TextField } from "../components/TextField";
import { FormButtonContainer } from "../components/FormButtonContainer";
import KeyboardEventHandler from "react-keyboard-event-handler";

export const ChooseCompanyType = () => {
  const { companyType, setCompanyType } = useFormDataContext();
  const [value, setValue] = useState(companyType);
  const navigate = useNavigate();

  const handleClick = () => {
    setCompanyType(value);
    navigate("/company-name");
  };
  return (
    <>
      <GenericPageLayout>
        <GenericCard
          title={"What kind of company are you?"}
          subtitle={
            "Example: Car manufacturer, take-away restaurant, beauty product webshop"
          }
          quote={`‘‘Companies from different industries gain valuable insights from Business Model Hacking’’`}
        >
          <Box
            bgcolor={"#D9D9D966"}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            paddingX={"30px"}
            borderRadius={"16px"}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "50%",
              }}
            >
              <KeyboardEventHandler
                handleKeys={["enter"]}
                onKeyEvent={(key, e) => handleClick()}
                style={{ width: "100%" }}
              >
                <TextField
                  autoFocus={true}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </KeyboardEventHandler>
            </Box>
            <FormButtonContainer>
              <Button
                component={motion.div}
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.3 },
                }}
                whileTap={{ scale: 0.9 }}
                onClick={handleClick}
                variant="contained"
                disabled={!value}
              >
                Next
              </Button>
            </FormButtonContainer>
          </Box>
        </GenericCard>
      </GenericPageLayout>
    </>
  );
};
