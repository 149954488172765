import { Box, List, ListItem, Typography, useMediaQuery } from "@mui/material";
import PlusIcon from "../assets/images/plusIcon.svg";
import MinusIcon from "../assets/images/minusIcon.svg";

export const ProsAndConsGrid = ({ pros, cons }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const list = [
    {
      title: "Opportunities",
      items: pros,
      logo: PlusIcon,
    },
    {
      title: "Challenges",
      items: cons,
      logo: MinusIcon,
    },
  ];
  return isMobile ? (
    list.map((item, i) => (
      <>
        <Box
          display={"flex"}
          border={"1px solid #000000"}
          borderRadius={"11px"}
          // width={"800px"}
          flexDirection={"column"}
        >
          <Box
            key={i}
            display={"flex"}
            sx={{
              height: "40px",
              justifyContent: "center",
              padding: "4px",
              alignItems: "center",
            }}
            bgcolor={"#D9D9D999"}
          >
            <img
              src={item.logo}
              width={30}
              height={30}
              style={{ margin: "10px" }}
              alt={item.title}
            />
            <Typography textAlign={"center"} fontWeight={700} fontSize={14}>
              {item.title}
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"row"} flex={1} width={"100%"}>
            <Box
              sx={{
                paddingRight: "30px",
              }}
            >
              <ul>
                {item.items.map((item) => (
                  <li>
                    <Typography
                      letterSpacing={0.4}
                      sx={{ fontFamily: "Montserrat, sans-serif" }}
                    >
                      {item}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
        </Box>
      </>
    ))
  ) : (
    <>
      <Box
        display={"flex"}
        border={"1px solid #000000"}
        borderRadius={"11px"}
        // width={"800px"}
        flexDirection={"column"}
      >
        <Box
          display={"flex"}
          sx={{
            height: "40px",
            justifyContent: "space-between",
            padding: "4px",
            alignItems: "center",
          }}
          bgcolor={"#D9D9D999"}
        >
          <img
            src={PlusIcon}
            width={30}
            height={30}
            style={{ margin: "10px" }}
          />
          <Typography textAlign={"center"} fontWeight={700} fontSize={14}>
            Opportunties & challenges
          </Typography>
          <img
            src={MinusIcon}
            width={30}
            height={30}
            style={{ margin: "10px" }}
          />
        </Box>
        <Box display={"flex"} flexDirection={"row"} flex={1} width={"100%"}>
          <Box
            flex={1 / 2}
            paddingLeft={isMobile && "18px"}
            borderRight={"1px solid #000000"}
          >
            <ul
              style={{
                padding: isMobile && 0,
              }}
            >
              {pros.map((item) => (
                <li>
                  <Typography sx={{ fontFamily: "Montserrat, sans-serif" }}>
                    {item}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
          <Box flex={1 / 2} paddingLeft={isMobile && "18px"}>
            <ul
              style={{
                padding: isMobile && 0,
              }}
            >
              {cons.map((item) => (
                <li>
                  <Typography sx={{ fontFamily: "Montserrat, sans-serif" }}>
                    {item}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      </Box>
    </>
  );
};
