import "./App.css";
import {
  FormDataProvider,
  FormDataProviderOutlet,
} from "./Utils/formDataContext";
import { ChooseBusinessModel } from "./views/ChooseBusinessModel";
import { ChooseCompanyName } from "./views/ChooseCompanyName";
import { ChooseCompanyType } from "./views/ChooseCompanyType";
import { GenerateIdeasPage } from "./views/GenerateIdeasPage";
import { ResultsPage } from "./views/ResultsPage";
import { ManagementResults } from "./views/ManagementResults";
import { PayPerUseResults } from "./views/PayPerUseResults";
import { PerformanceResults } from "./views/PerformanceResults";
import { ReviewIdeasPage } from "./views/ReviewIdeasPage";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import { ThemeProvider } from "@mui/material";

const router = createBrowserRouter([
  {
    path: "/",
    element: <FormDataProviderOutlet />,
    children: [
      {
        path: "/",
        element: <ChooseBusinessModel />,
        // element: <GenerateIdeasPage />,
        // element: <ReviewIdeasPage />,
        // element: <ResultsPage />,
      },
      {
        path: "/company-type",
        element: <ChooseCompanyType />,
      },
      {
        path: "/company-name",
        element: <ChooseCompanyName />,
      },
      {
        path: "/generate-ideas",
        element: <GenerateIdeasPage />,
      },
      {
        path: "/review-ideas",
        element: <ReviewIdeasPage />,
      },
      // {
      //   path: "/results",
      //   element: <ResultsPage />,
      // },
      {
        path: "/results",
        element: <ResultsPage />,
      },
    ],
  },
]);

function App() {
  return (
    <SnackbarProvider
      SnackbarProps={{
        autoHideDuration: 4000,
        anchorOrigin: { vertical: "top", horizontal: "center" },
      }}
    >
      <RouterProvider router={router} />
    </SnackbarProvider>
  );
  // {/*<ChooseBusinessModel/>*/}
  // {/* <ChooseCompanyType/> */}
  // {/* <ChooseCompanyName/> */}
  // {/* <GenerateIdeasPage/> */}
  // {/* <ReviewIdeasPage/> */}
  // {/* <ResultsPage/> */}
  // {/* <ManagementResults/> */}
  // {/* <PerformanceResults/> */}
  // {/* <PayPerUseResults/> */}
}

export default App;
