import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
// import Logo from "../assets/images/BusinessModelLogo.svg";
import NewLogo from "../assets/images/NewLogo.jpg";
import { Button, Container, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from "../assets/images/closeIcon.svg";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

export default function MenuAppBar() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const size = isMobile ? "15vh" : "18vh";

  const [header, setHeader] = React.useState("");

  const location = useLocation();

  const headerNames = {
    "/": "Ready to Boost Your Business? (1/3)",
    "/company-type": "Ready to Boost Your Business? (2/3)",
    "/company-name": "Ready to Boost Your Business? (3/3)",
    "/generate-ideas": "In Progress",
    "/review-ideas": "In Progress",
    "/results": "Ready to Boost Your Business?",
  };

  React.useEffect(() => {
    const pathname = location.pathname;
    const newHeaderName = headerNames[pathname];
    setHeader(newHeaderName);
  }, [location.pathname]);

  const resultsPage = location.pathname === "/results";

  const onCloseClick = () => {
    window.location.href = "https://www.businessmodelhacking.com";
  };

  const onSubscribeClick = () => {
    window.location.href = "https://www.businessmodelhacking.com/nextideahacker";
  };

  return (
    <AppBar
      position="static"
      variant="outlined"
      sx={{ backgroundColor: "transparent", borderBottom: "none" }}
    >
      <Toolbar>
        <Container
          maxWidth="lg"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            // position={"absolute"}
            // bgcolor={"#ffffff"}
            height={size}
            // width={isMobile ? "22vh" : "30vh"}
            minWidth={size}
            style={{ cursor: "pointer" }}
            alignContent={"center"}
            sx={{
              marginTop: isMobile ? "20px" : "0",
              // padding: isMobile && "20px",
            }}
          >
            <a href="https://www.businessmodelhacking.com">
            <img
              src={NewLogo}
              
              objectFit={"contain"}
              style={{
                justifyContent: "center",
                maxHeight: '100%',
                maxWidth: '100%',
                // border: "3px solid green",
              }}
              alt="logo"
            />
            </a>
          </Box>
          {!isMobile && (
            <Box
              display={"flex"}
              flexDirection={"row"}
              width={"100%"}
              alignItems={"center"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                padding={isMobile ? "20px 10px" : "20px"}
                width={"100%"}
                height={"50px"}
                border={"1px solid #FFFFFF"}
                borderLeft={"none"}
                borderRadius={"0px 20px 20px 0px"}
              >
                <Box flex={7 / 8}>
                  <Typography
                    display={"block"}
                    textAlign={"center"}
                    color={"#ffffff"}
                    fontWeight={800}
                    fontSize={isMobile ? "18px" : "25px"}
                  >
                    {/* Business Model Hacking Demo */}
                    {header}
                  </Typography>
                </Box>

                <Box display={"flex"} flex={1 / 8} justifyContent={"right"}>
                  {!resultsPage && (
                    <img
                      src={CloseIcon}
                      width={isMobile ? "20px" : "30px"}
                      height={"30px"}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={onCloseClick}
                    />
                  )}

                  {!isMobile && resultsPage && (
                    <Button
                      onClick={onSubscribeClick}
                      sx={{
                        width: "100%",
                        maxWidth: "100%",
                        marginBottom: "2px",
                        backgroundColor: "#009DE2",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                      component={motion.div}
                      whileHover={{
                        scale: 1.1,
                        transition: { duration: 0.3 },
                      }}
                      whileTap={{ scale: 0.9 }}
                      variant="contained"
                    >
                      Subscribe
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Container>
      </Toolbar>
    </AppBar>
  );
}
