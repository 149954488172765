export const BUSINESS_MODELS = {
  YIELD_MANAGEMENT: "Yield Management",
  PERFORMANCE_BASED_CONTRACTING: "Performance Based Contracting",
  PAY_PER_USE: "Pay Per Use",
};

export const BUSINESS_MODEL_DATA = {
  [BUSINESS_MODELS.YIELD_MANAGEMENT]: {
    opportunities: [
      "Can help to make more profit",
      "Can help to optimize stock",
      "Can help to respond to market conditions",
    ],
    challenges: [
      "Customers paying more can feel it's unfair.",
      "Price fluctuations during orientation create negative experiences.",
      "Application processes are complex and resource-intensive.",
      "Success requires understanding customers, supply & demand, and big data",
    ],
    questions: [
      "Can you adjust the prices to the remaining supply or the increased demand?",
      "Is there sufficient knowledge about the customer, supply and demand and data to correctly manage this model?",
      "What advantages does the yield management model offer?",
      "Do customers accept price fluctuations or is this an obstacle for your customers?",
    ],
  },
  [BUSINESS_MODELS.PERFORMANCE_BASED_CONTRACTING]: {
    opportunities: [
      "Win-win potential",
      "Focus on outcomes rather than efforts leads to a more goal-driven approach",
      "Basis for close operations",
    ],
    challenges: [
      "There may be a mismatch in the expectations of the provider and client regarding performance.",
      "KPI overload",
      "Uncovered risks",
      "Trust factor",
      "Win-win agreement for both parties can be complex",
    ],
    questions: [
      "What do your company's customers really need?",
      "Is it possible to link the result to a pre-agreed amount?",
      "How can your company improve delivery and reliability in the value chain?",
      "Can the revenue model be split into a fixed part and a flexible part, whereby customers pay if the agreed performance is achieved?",
    ],
  },
  [BUSINESS_MODELS.PAY_PER_USE]: {
    opportunities: [
      "Pay per use is appealing to customers valuing flexibility and transparency, willing to pay more for these benefits.",
      "Can give competitive advantage",
      "Can help to maximize profits by aligning costs directly with usage.",
    ],
    challenges: [
      "Fluctuating usage leads to revenue uncertainty.",
      "Demand variability presents operational challenges in resource management.",
      "Combining pay-per-use with a base fee or implementing minimum usage terms helps mitigate risks.",
    ],
    questions: [
      "Is extra value created for the customer when introducing the pay per use model?",
      "What are the consequences of introducing this model for the other building blocks of the business model?",
      "Will customers change their behavior when introducing the pay per use model?",
      "Can your company beat the competition by switching to pay per use?",
    ],
  },
};
