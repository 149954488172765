import { Box, List, ListItem, Typography } from "@mui/material";
import QuestionIcon from "../assets/images/quizIcon.svg";

export const QuestionsGrid = ({ modelType, questionsList }) => {
  return (
    <Box
      display={"flex"}
      border={"1px solid #000000"}
      borderRadius={"11px"}
      // width={"800px"}
      flexDirection={"column"}
    >
      <Box
        display={"flex"}
        sx={{
          height: "40px",
          justifyContent: "space-between",
          padding: "4px",
          alignItems: "center",
        }}
        bgcolor={"#D9D9D999"}
      >
        <img
          src={QuestionIcon}
          width={30}
          height={30}
          style={{ margin: "10px" }}
        />
        <Typography textAlign={"center"} fontWeight={700} fontSize={14}>
          Innovation questions
        </Typography>
        <Box></Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flex={1}
        sx={{ display: "flex" }}
        padding={"20px"}
      >
        <Typography
          textAlign={"left"}
          fontStyle={"italic"}
          fontWeight={400}
          fontSize={"16px"}
        >
          You should ask yourself the questions below if you are considering
          applying {modelType}
        </Typography>
        <Box display={"flex"} width={"100%"}>
          <ul sx={{ width: "95%", fontFamily: "Montserrat, sans-serif" }}>
            {questionsList.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </Box>
      </Box>
    </Box>
  );
};
