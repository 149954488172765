import { Box, Typography, useMediaQuery } from "@mui/material";
import { GenericPageLayout } from "../components/GenericPageLayout";
import GlobeImage from "../assets/images/globe.svg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import { useFormDataContext } from "../Utils/formDataContext";

export const ReviewIdeasPage = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const { companyName } = useFormDataContext();

  useEffect(() => {
    var t = setTimeout(() => {
      // navigate("/results");
      navigate("/generate-ideas");
    }, 4000); //4000
    return () => clearTimeout(t);
  }, []);

  return (
    <GenericPageLayout>
      <Box
        display={"flex"}
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        <Box
          width={ isMobile ? "auto" : "70%"}
          height={isMobile ? "auto" : "70%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {/* <img
            src={GlobeImage}
            height={'300px'}
            width={'300px'}
            style={{ alignSelf: "center" }}
            alt='globe'
          /> */}
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: require("../assets/animations/animation2.json"),
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={350}
            width={350}
          />
          <Typography
            fontWeight={800}
            fontSize={{ lg: "36px", md: "30px", xs: "26px" }}
            color={"white"}
            textAlign={"center"}
          >
            Our AI is busy to generate new ideas for {companyName}
            {/*Congratulations innovator, your first result with our IdeaHacker is ready to review.*/}
          </Typography>
        </Box>
      </Box>
    </GenericPageLayout>
  );
};
