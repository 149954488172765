import { createContext, useState, useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import BackgroundImage from "../assets/images/background.jpeg";

const FormDataContext = createContext();

export const FormDataProvider = ({ children }) => {
  const [businessModel, setBusinessModel] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [result, setResult] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (!businessModel) {
      navigate("/");
    } else if (!companyType) {
      navigate("/company-type");
    } else if (!companyName) {
      navigate("/company-name");
    } else if (!result) {
      navigate("/generate-ideas");
    }
  }, [businessModel, companyType, companyName, result, navigate]);

  return (
    <FormDataContext.Provider
      value={{
        businessModel,
        setBusinessModel,
        companyType,
        setCompanyType,
        companyName,
        setCompanyName,
        result,
        setResult,
      }}
    >
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormDataContext = () => {
  const context = useContext(FormDataContext);
  if (context === undefined) {
    throw new Error(
      "useFormDataContext must be used within a FormDataProvider"
    );
  }
  return context;
};

export const FormDataProviderOutlet = () => {
  return (
    <FormDataProvider>
      <div
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          resizeMode: "cover",
          backgroundSize: "cover",
          }}
      >
        <div style={{ 
          // backgroundColor: "#AB2435CC", // #AB2435CC
          backgroundColor: "rgba(171, 36, 53, 0.9)",
        }}>
          <Outlet />
        </div>
      </div>
    </FormDataProvider>
  );
};
