import { BUSINESS_MODELS } from "../Utils/constants";
import { useFormDataContext } from "../Utils/formDataContext";
import { ManagementResults } from "./ManagementResults";
import { PayPerUseResults } from "./PayPerUseResults";
import { PerformanceResults } from "./PerformanceResults";

export const ResultsPage = () => {
  const { businessModel } = useFormDataContext();

  switch (businessModel) {
    case BUSINESS_MODELS.YIELD_MANAGEMENT:
      return <ManagementResults />;
    case BUSINESS_MODELS.PERFORMANCE_BASED_CONTRACTING:
      return <PerformanceResults />;
    case BUSINESS_MODELS.PAY_PER_USE:
      return <PayPerUseResults />;
    default:
      return <></>;
  }
};
