import { Box, Button } from "@mui/material";
import { GenericCard } from "../components/GenericCard";
import { GenericPageLayout } from "../components/GenericPageLayout";
import { useState } from "react";
import { useFormDataContext } from "../Utils/formDataContext";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { TextField } from "../components/TextField";
import { FormButtonContainer } from "../components/FormButtonContainer";
import KeyboardEventHandler from "react-keyboard-event-handler";
import useBackButton from "../Utils/useBackButton";

export const ChooseCompanyName = () => {
  // const [companyName, setCompanyName] = useState('')
  const { companyName, setCompanyName } = useFormDataContext();
  const navigate = useNavigate();
  const handleClick = () => {
    // navigate("/generate-ideas");
    navigate("/review-ideas");
  };

  // const toDo = () => {
  //   console.log("hello world!");
  //   navigate("/"); // we will redirect to contact page when click on browser back or forward button. if you want to redirect to 'home' page, you can replace '/contact' with your homepage route (e.g. '/home' or '/' etc.).

  //   // you can insert your desire code in this function.
  // };
  // const isBackPressed = useBackButton(toDo);

  // console.log(isBackPressed);

  return (
    <>
      <GenericPageLayout>
        <GenericCard
          title={"What's the name of your Company?"}
          quote={`‘‘We use it to personalize the result’’`}
        >
          <Box
            bgcolor={"#D9D9D966"}
            width={"100%"}
            // height={"120px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            borderRadius={"16px"}
            // padding={"50px"}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "50%",
              }}
            >
              <KeyboardEventHandler
                handleKeys={["enter"]}
                onKeyEvent={(key, e) => handleClick()}
                style={{ width: "100%" }}
              >
                <TextField
                  autoFocus={true}
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </KeyboardEventHandler>
            </Box>
            <FormButtonContainer
              sx={{
                display: "flex",
                alignSelf: "flex-start",
                padding: "20px",
              }}
            >
              <Button
                component={motion.div}
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.3 },
                }}
                whileTap={{ scale: 0.9 }}
                onClick={handleClick}
                variant="contained"
                disabled={!companyName}
              >
                Next
              </Button>
            </FormButtonContainer>
          </Box>
        </GenericCard>
      </GenericPageLayout>
    </>
  );
};
