import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { GenericPageLayout } from "../components/GenericPageLayout";
import { IdeaCard } from "../components/IdeaCard";
import { ProsAndConsGrid } from "../components/ProsAndConsGrid";
import { QuestionsGrid } from "../components/QuestionsGrid";
import { SocialMediaContainer } from "../components/SocialMediaContainer";
import IdeaBulb from "../assets/images/ideaBulb.svg";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useBackButton from "../Utils/useBackButton";

const ExplainationCard = ({ image, imageTitle, quotation, subtitle }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useMediaQuery("(max-width:600px)");

  // window.onpopstate = () => {
  //   navigate("/company-name", { state: { from: location.pathname } });
  // };


  return (
    <Box
      display={"flex"}
      border={"1px solid #000000"}
      borderRadius={"11px"}
      flexDirection={{ lg: "row", md: "column", xs: "column" }}
    >
      <Box
        display={"flex"}
        flex={1 / 4}
        bgcolor={"#B0B0B099"}
        flexDirection={"column"}
        sx={{ justifyContent: "center", alignItems: "center" }}
        borderRadius={"11px"}
        padding={"20px"}
      >
        <img src={image} height={"100px"} width={"100px"} />
        <Typography
          fontSize={"14px"}
          fontWeight={700}
          sx={{ textAlign: "center" }}
        >
          {imageTitle}
        </Typography>
      </Box>
      <Box
        flex={3 / 4}
        padding={"40px"}
        sx={{ display: "flex", justifyContent: "center", minHeight: "100px",
        maxHeight: "auto" }}
        flexDirection={"column"}
      >
        <Stack
          display={"flex"}
          flexDirection={"row"}
          columnGap={2}
          justifyContent={"left"}
          alignItems={"center"}
        >
        {!isMobile &&  <Box height={"100px"} width={"20px"} bgcolor={"#9E3339E5"} />}
          <Typography fontWeight={700} textAlign={"left"}>
            {"Explaination: "}
            <span style={{ fontWeight: 400 }}>{quotation}</span>
          </Typography>
        </Stack>
        <Typography
          fontWeight={400}
          fontSize={14}
          textAlign={"left"}
          marginTop={4}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

const ContentCard = ({
  CompanyName,
  IdeaList,
  image,
  imageTitle,
  quotation,
  subtitle,
  pros,
  cons,
  modelType,
  questionsList,
}) => {
  const onCloseClick = () => {
    window.location.href = "http://www.businessmodelhacking.com/nextideahacker";
  };
  return (
    <>
      <Card
        sx={{
          background: "rgba(255, 255, 255, 0.9)",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(2.1px)",
          "-webkit-backdrop-filter": "blur(2.1px)",
          paddingX: "5%",
          paddingY: "10px",
          borderRadius: "16px",
        }}
      >
        <CardContent>
          <Stack spacing={3}>
            <Typography textAlign={"center"} fontSize={36} fontWeight={800}>
              Result for {CompanyName}
            </Typography>
            <ExplainationCard
              image={image}
              imageTitle={imageTitle}
              quotation={quotation}
              subtitle={subtitle}
            />
            {IdeaList.map((item, index) => (
              <>
                <IdeaCard
                  title={item.title}
                  subtitle={item.subtitle}
                  image={IdeaBulb}
                  index={index + 1}
                />
              </>
            ))}
            <ProsAndConsGrid pros={pros} cons={cons} />
            <QuestionsGrid
              modelType={modelType}
              questionsList={questionsList}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  width: "60%",
                  marginBottom: "2px",
                  backgroundColor: "#009DE2",
                  textTransform: "none",
                }}
                component={motion.div}
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.3 },
                }}
                whileTap={{ scale: 0.9 }}
                variant="contained"
                onClick={onCloseClick}
              >
                Next
              </Button>
            </Box>
          </Stack>
          <SocialMediaContainer
            {...{
              CompanyName,
              IdeaList,
              image,
              imageTitle,
              quotation,
              subtitle,
              pros,
              cons,
              modelType,
              questionsList,
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};

export const ResultsPage = ({
  CompanyName,
  IdeaList,
  image,
  imageTitle,
  quotation,
  subtitle,
  pros,
  cons,
  modelType,
  questionsList,
}) => {
  return (
    <GenericPageLayout>
      <ContentCard
        CompanyName={CompanyName}
        IdeaList={IdeaList}
        image={image}
        imageTitle={imageTitle}
        quotation={quotation}
        subtitle={subtitle}
        pros={pros}
        cons={cons}
        modelType={modelType}
        questionsList={questionsList}
      />
    </GenericPageLayout>
  );
};
