import { Box, Stack, Typography } from "@mui/material";
import ManagementImage from "../assets/images/management.svg";
import PerformanceImage from "../assets/images/performanceImage.svg";
import SettingsImage from "../assets/images/settingsImage.svg";
import { GenericCard } from "../components/GenericCard";
import { GenericPageLayout } from "../components/GenericPageLayout";
import { useFormDataContext } from "../Utils/formDataContext";
import { Button } from "@mui/material";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useNavigate } from "react-router-dom";
import { BUSINESS_MODELS } from "../Utils/constants";
import { motion } from "framer-motion";

const DetailsList = [
  {
    image: ManagementImage,
    title: BUSINESS_MODELS.YIELD_MANAGEMENT,
  },
  {
    image: PerformanceImage,
    title: BUSINESS_MODELS.PERFORMANCE_BASED_CONTRACTING,
  },
  {
    image: SettingsImage,
    title: BUSINESS_MODELS.PAY_PER_USE,
  },
];

export const ChooseBusinessModel = () => {
  const { setBusinessModel, businessModel } = useFormDataContext();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const onNextClick = () => {
    // if (businessModel) {
    //   // navigate to next page
    navigate("/company-type");
    // snackbar.showMessage("Navigating to next page",);
    // } else {
    //   snackbar.showMessage("Please select a business model",);
    // }
  };

  return (
    <>
      <GenericPageLayout>
        <GenericCard
          title={"Choose a Business Model Hack"}
          subtitle={
            "Answer 3 simple questions and get new business model ideas."
          }
          quote={`‘‘I challenge you to experience how it works’’`}
        >
          <Stack
            direction={{ lg: "row", md: "column", xs: "column" }}
            // spacing={3}
            flex={1}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {DetailsList.map((item) => {
              const selected = businessModel === item.title;
              return (
                <motion.div
                  variant={!selected ? "ghost" : "outlined"}
                  color={selected ? "error" : "info"}
                  padding={0}
                  component={motion.div}
                  whileHover={{
                    scale: 1.1,
                    transition: { duration: 0.3 },
                  }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Box
                    bgcolor={selected ? "#ffD9D966" : "#D9D9D966"}
                    width={"220px"}
                    height={"220px"}
                    display={"flex"}
                    borderRadius={"16px"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    padding={"5px"}
                    onClick={() => {
                      setBusinessModel(item.title);
                      onNextClick();
                    }}
                    margin={1}
                  >
                    <img src={item.image} width={"100px"} height={"100px"} />
                    <Typography
                      fontWeight={800}
                      fontSize={18}
                      textAlign={"center"}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                </motion.div>
              );
            })}
          </Stack>
          {/* <Button variant={"contained"} onClick={}>
              Next
            </Button> */}
        </GenericCard>
      </GenericPageLayout>
    </>
  );
};
